import clsx from "clsx"
import { ComponentProps, PropsWithChildren } from "react"

export function Stub(props: ComponentProps<"div"> & { error?: boolean }) {
  return (
    <div
      {...props}
      className={clsx(
        "p-2 border type-body-s-roman flex items-center justify-center",
        props.error ? "bg-error-light border-error-dark" : "bg-glass border-grey-mid border-dashed",
        props.className,
      )}
    />
  )
}
